import { ValidationError } from 'yup';

interface validateErrors {
  [key: string]: string;
}
export default function getValidationErrors(
  error: ValidationError,
): validateErrors {
  const validationErrors: validateErrors = {};

  error.inner.forEach(err => {
    validationErrors[err.path] = err.message;
  });

  return validationErrors;
}
